const ABOUT = '/' as const;
const SUPPORT = '/support' as const;
const LICENSE = '/licenseagreement' as const;
const POLITICS = '/politics' as const;
const USER_AGREEMENT = '/useragreement' as const;
const PERSONAL_AGREEMENT = '/personalagreement' as const;
const AUTHORIZATION_AGREEMENT = '/authagreement' as const;
const NOT_FOUND = '*' as const;

export const COMMON_ROUTES = {
  ABOUT,
  SUPPORT,
  LICENSE,
  POLITICS,
  USER_AGREEMENT,
  PERSONAL_AGREEMENT,
  AUTHORIZATION_AGREEMENT,
  NOT_FOUND,
};

export const NAV_ROUTES = [
  { text: 'Поддержка', href: SUPPORT },
  { text: 'Лицензионное соглашение', href: LICENSE },
  { text: 'Политика конфиденциальности', href: POLITICS },
  { text: 'Пользовательское соглашение', href: USER_AGREEMENT },
  { text: 'Согласие на обработку персональных данных', href: PERSONAL_AGREEMENT },
  {
    text: 'Согласие на обработку ПДн в целях авторизации',
    href: AUTHORIZATION_AGREEMENT,
  },
];
